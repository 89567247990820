@import "../../shared/styles/fonts";
@import "../../shared/styles/colors";
@import "../../shared/styles/utilities";

.Loader {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 0;
	padding: 0;
	margin: 50px auto;
}
.Loader span {
	vertical-align:middle;
	border-radius:100%;

	display:inline-block;
	width:10px;
	height:10px;
	margin:3px 2px;
	-webkit-animation:Loader 0.8s linear infinite alternate;
	animation:Loader 0.8s linear infinite alternate;
}
.Loader span:nth-child(1) {
	-webkit-animation-delay:-1s;
	animation-delay:-1s;
	@include background-opacity($color-blue, 0.6)
}
.Loader span:nth-child(2) {
	-webkit-animation-delay:-0.8s;
	animation-delay:-0.8s;
	@include background-opacity($color-blue, 0.8)
}
.Loader span:nth-child(3) {
	-webkit-animation-delay:-0.26666s;
	animation-delay:-0.26666s;
	@include background-opacity($color-blue, 1)
}
.Loader span:nth-child(4) {
	-webkit-animation-delay:-0.8s;
	animation-delay:-0.8s;
	@include background-opacity($color-blue, 0.8)

}
.Loader span:nth-child(5) {
	-webkit-animation-delay:-1s;
	animation-delay:-1s;
	@include background-opacity($color-blue, 0.4)
}

@keyframes Loader {
	from {transform: scale(0, 0);}
	to {transform: scale(1, 1);}
}
@-webkit-keyframes Loader {
	from {-webkit-transform: scale(0, 0);}
	to {-webkit-transform: scale(1, 1);}
}
