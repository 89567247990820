@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
html {
	min-height: 100vh;
	/* mobile viewport bug fix */
	min-height: -webkit-fill-available;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
	min-height: 100vh;
	/* mobile viewport bug fix */
	min-height: -webkit-fill-available;
	-webkit-overflow-scrolling: touch;
}

.App {
	position: relative;
}
