@import "../../shared/styles/colors";
@import "../../shared/styles/fonts";
@import "../../shared/styles/variables";
@import "../../shared/styles/breakpoints";

.BackButton {
	margin: 0 0 20px;
	display: flex;
	align-items: center;

	span {
		@include font-roboto(1rem, $color-text--grey, 500);
		display: inline-flex;
		transition: color 300ms;
	}

	&:hover {
		text-decoration: none;

		span {
			color: $color-text--black
		}

		svg {
			color: $color-text--black
		}
	}

	svg {
		margin: 0 5px 0 0;
		color: $color-text--grey;
		transform: translateY(-1px);
		transition: color 300ms;
	}
}
