@import "../../shared/styles/variables";
@import "../../shared/styles/utilities";

.Home {
	position: relative;
	@include fadeIn(300ms);
	margin: 60px 0 0;
}

.Home-content {
	position: relative;
	max-width: $max-width-content;
	padding: 15px 20px 30px;
	margin: 30px auto;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column;

	> a {
		margin: 0 0 30px;
	}
}
