/*
 *	Fonts
 *
 *	It's necessary to create fonts folder for custom fonts and import files into globals.css
 *  Remember to use "font-display: swap" property for improving performances
 *
 *	How to use: Mixin for settings size, color, weight, line-height and letter spacing
 * 	e.g: @include font-base(1.25rem, $color-black, 700);
 *
 * About font weight, we have these options:
 * - 400 regular (is the default, can be omitted)
 * - 500 medium
 * - 700 bold
*/

// Settings and Mixin. Use custom font name.
$font-roboto: 'Roboto', Helvetica, Arial, sans-serif;

@mixin font-roboto(
  $size: false,
  $colour: false,
  $weight: false,
  $lh: false,
  $ls: false
) {
  font-family: $font-roboto;

  @if $size {
    font-size: $size;
  }

  @if $colour {
    color: $colour;
  }

  @if $weight {
    font-weight: $weight;
  }

  @if $lh {
    line-height: $lh;
  }

  @if $ls {
    letter-spacing: $ls + px;
  }
}
