@import "../../../shared/styles/fonts";
@import "../../../shared/styles/colors";
@import "../../../shared/styles/breakpoints";

.TableHead {
	display: none;

	@include desktop {
		display: table-header-group;
	}

	.TableHead-column {
		@include font-roboto(0.75rem, $color-acquamarine, 500);
		text-transform: uppercase;
		border:0 !important;
		padding: 15px 8px !important;

		&.TableHead-column--170 {
			min-width: 170px;
		}

		&.TableHead-column--300 {
			min-width: 300px;
		}
	}
}
