@import "../../shared/styles/utilities";

.Table {
	position: relative;
	@include fadeIn(300ms);
}

.Table-container {
	position: relative;
	@include fadeIn(300ms);
	width: 100%;
}
