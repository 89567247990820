/*
 *	Breakpoints
 *
 *	Mobile first
 * 	e.g.: @include tablet { padding: 20px; }
 *
*/

/* Phones / sm (landscape phones, 576px and up) */
@mixin mobile {
  @media (min-width: 576px) {
    @content;
  }
}

/* Tablets / md (tablets, 768px and up) */
@mixin tablet {
  @media (min-width: 768px) {
    @content;
  }
}

/* Desktops / lg (desktops, 992px and up) */
@mixin desktop {
  @media (min-width: 992px) {
    @content;
  }
}

/* Large desktops / xLg (large desktops, 1200px and up) */
@mixin large-desktop {
  @media (min-width: 1200px) {
    @content;
  }
}
