@import "../../shared/styles/fonts";
@import "../../shared/styles/colors";
@import "../../shared/styles/utilities";

.SearchField {
	position: relative;
	width: 100%;
	max-width: 400px;

	> input {
		padding: 8px 40px 8px 12px;
		border-radius: 4px;
		border: 1px solid $color-grey;
		width: 100%;
		box-sizing: border-box;
		@include font-roboto(1rem, $color-text--black, 400);

		&:focus {
			outline: 0;
			box-shadow: none;
			border-color: $color-text--placeholder;
		}

		@include input-placeholder {
			@include font-roboto(1rem, $color-text--placeholder, 400);
		}
	}

	svg {
		position: absolute;
		top: 8px;
		right: 14px;
	}
}
