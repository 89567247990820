@import "../../shared/styles/fonts";
@import "../../shared/styles/colors";
@import "../../shared/styles/breakpoints";

.NoData {
	position: relative;
}

.NoData-content {
	margin: 100px auto;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column;

	> span {
		@include font-roboto(1.25rem, $color-red, 500);
		display: block;
		margin: 0 auto 30px;
		text-align: center;
	}

	> button {
		background-color: transparent;
		@include font-roboto(1rem, $color-blue, 500);
		text-transform: uppercase;
		letter-spacing: 0.6px;
		border: 1px solid $color-blue;
		padding: 6px 12px;
		transition: opacity 300ms;

		@include large-desktop {
			&:hover {
				opacity: 0.8;
			}
		}
	}
}
