/*
 *	Utilities
 *
 *	Misc for different cases such as placeholder style or background color, etc..
 *	e.g: .MyInputClass { @include input-placeholder { color: $color-grey; } }
 *
*/

@import '../styles/colors';

@mixin input-placeholder {
  &.placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}

@mixin background-opacity($color, $opacity: 0.3) {
  background: $color;
  /* The Fallback */
  background: rgba($color, $opacity);
}

@mixin color-opacity($color, $opacity: 0.3) {
  color: rgba($color, $opacity);
}

@mixin border($top, $right, $bottom, $left, $type, $color) {
  border: $top $right $bottom $left $type $color;
}

@mixin keyframe-fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  @include keyframe-fadeIn;
}

@mixin fadeIn($arg) {
  $keyframe-name: fadeIn;
  $duration: $arg;
  animation: $keyframe-name $duration;
}
