@import "../../shared/styles/breakpoints";
@import "../../shared/styles/fonts";
@import "../../shared/styles/colors";

.Overall {
	background-color: $color-grey;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 30px;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 99999;

	> strong {
		font-weight: 700;
		margin: 0 3px 0 0;
	}

	@include font-roboto(0.875rem, $color-text--black, 500);
}
