/*
 *	Colors
 * 	Syntax: $color-{colorName}{--modifier?}
 *	{modifier} => optional
 *
 *	Colors used in App. Extend this sheet for other colors.
 *	e.g: .MyTextClass { color: $color-red; }
 *
 *  Important: Edit ui/Colors.stories.mdx after updating this file!
 *
*/

$color-white: #fff;
$color-black: #000;
$color-text--black: #27282d;
$color-text--grey: #53646f;
$color-text--placeholder: #9ea1a2;
$color-grey: #dde4e4;
$color-acquamarine: #01b9d1;
$color-blue: #3b5998;
$color-yellow: #faeb4a;
$color-orange: #f57a3b;
$color-red: #f15d5b;
$color-green: #6cdb56;
$color-green--dark: #286b1a;
