@import "../../shared/styles/fonts";
@import "../../shared/styles/colors";
@import "../../shared/styles/breakpoints";

.Status {
	@include font-roboto(0.875rem, $color-text--placeholder, 400);
	display: inline-block;
	white-space: nowrap;

	&:before{
		content: '';
		margin-bottom: 0;
		width: 9px;
		height: 9px;
		display: inline-block;
		margin-right: 7px;
		border-radius: 50%;
	}

	&.Status--red {
		color: $color-red;
		opacity: 0.5;
		font-weight: 500;

		&:before{
			background-color: $color-red;
		}
	}

	&.Status--green {
		color: $color-green;
		font-weight: 500;

		&:before{
			background-color: $color-green;
		}
	}
}

