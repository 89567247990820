@import "../../shared/styles/colors";
@import "../../shared/styles/fonts";
@import "../../shared/styles/variables";
@import "../../shared/styles/breakpoints";

.NavLink {
	position: relative;
	@include font-roboto(1.125rem, $color-white, 500);
	padding: 20px;
	border-radius: 8px;
	text-decoration: none;
	transition: all 250ms;
	text-align: center;
	background-color: $color-blue;
	box-sizing: border-box;
	border: 2px solid $color-blue;
	width: 100%;
	max-width: 500px;

	&.NavLink--secondary {
		background-color: $color-green--dark;
		border: 2px solid $color-green--dark;
	}

	&:hover {
		color: $color-white;
		text-decoration: none;
		@include large-desktop {
			color: $color-blue;
			background-color: transparent;

			&.NavLink--secondary {
				color: $color-green--dark;
				border-color: $color-green--dark;
				background-color: transparent;
			}
		}
	}
}
