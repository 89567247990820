@import "../../shared/styles/colors";
@import "../../shared/styles/fonts";
@import "../../shared/styles/variables";

.RrfaList {
	position: relative;
	width: 100%;
}

.RrfaList-items {
	position: relative;
	margin: 0 0 50px;
}

.RrfaList-item {
	padding: 20px 0;
	border-bottom: 1px solid $color-text--grey;
	@include font-roboto(0.875rem, $color-black, 400, 1.125rem);

	&:last-child {
		border-bottom: 0;
	}
}
