@import "../../shared/styles/fonts";
@import "../../shared/styles/colors";
@import "../../shared/styles/breakpoints";

.DataInfo {
  margin: 0 0 10px;

  &:last-child {
    margin: 0;
  }
}

.DataInfo-sospeso {
  margin: 0;
  @include font-roboto(0.875rem, $color-white, 500, 1.125rem);
  letter-spacing: 0.5em;
  font-weight: bold;
  background-color: red;
}

.DataInfo-overall {
  margin: 0;
  @include font-roboto(0.875rem, $color-blue, 500, 1.125rem);
}

.DataInfo-title {
  margin-bottom: 0;
  @include font-roboto(0.875rem, $color-text--grey, 500, 1.125rem);
}

.DataInfo-link {
  @include font-roboto(0.875rem, $color-text--grey, 400, 1.125rem);
  margin-bottom: 0;
  text-decoration: underline;
  display: block;
}

.DataInfo-description {
  @include font-roboto(0.75rem, $color-text--placeholder, 400);
}
