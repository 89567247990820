@import "../../shared/styles/variables";
@import "../../shared/styles/utilities";

.Rla {
	position: relative;
	@include fadeIn(300ms);
	margin: 40px 0 0;
}

.Rla-content {
	position: relative;
	min-height: 850px;
	max-width: $max-width-content;
	padding: 15px 20px 30px;
	margin: 0 auto;
}
