@import "../../../shared/styles/fonts";
@import "../../../shared/styles/colors";
@import "../../../shared/styles/breakpoints";

.TableColumn {
	position: relative;
	display: block;
	text-align: center;
	padding: 8px;
	border: 0 !important;
	vertical-align: top;

	&:before {
		content:attr(data-column);
		@include font-roboto(0.75rem, $color-text--placeholder, 500);
		text-transform: uppercase;
		display: block;
	}

	@include desktop {
		padding: 12px 8px;
		text-align: left;
		display: table-cell;
		@include font-roboto(0.875rem, $color-text--grey, 400, 1.125rem);

		&:before {
			display: none;
		}
	}
}
