@import "../../shared/styles/breakpoints";
@import "../../shared/styles/fonts";
@import "../../shared/styles/colors";

.Header {
	position: relative;
	margin: 0 0 20px;

	@include desktop {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	> h2 {
		@include font-roboto(1.25rem, $color-text--grey, 700, 1.75rem);
		margin: 0 0 15px;

		@include desktop {
			margin: 0;
		}

		@include large-desktop {
			font-size: 1.375rem;
		}
	}
}
