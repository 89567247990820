@import "../../../shared/styles/fonts";
@import "../../../shared/styles/colors";
@import "../../../shared/styles/breakpoints";
@import "../../../shared/styles/utilities";

.TableRow {
	border: 1px solid #e4e9ea;
	border-radius: 8px;
	background-color: #fff;
	margin: 0 auto 20px;
	display: block;
	padding: 10px;
	@include fadeIn(300ms);

	@include desktop {
		border: 0;
		border-bottom: 1px solid #e4e9ea;
		border-radius: 0;
		margin: 0;
		display: table-row;
		padding: 0;
	}
}

.TableRow-empty {
	@include font-roboto(0.875rem, $color-text--placeholder, 400);
	opacity: 0.6;
}
